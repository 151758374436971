<template>
  <div id="result-scores-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <template v-if="ateResultScores">
        <div class="columns is-multiline">
          <div class="column is-6">
            <p class="title is-6 is-bolder mb-0 mb-2">Name</p>
            <p class="title is-5 has-background-light p-3">
              {{ ateResultScores.name }}
            </p>
          </div>
          <div class="column is-6">
            <p class="title is-6 is-bolder mb-0 mb-2">School Class</p>
            <p class="title is-5 has-background-light p-3">
              {{ ateResultScores.resultClass.name }}
            </p>
          </div>
          <div class="column is-12">
            <b-field label="Term">
              <b-select v-model="termId" placeholder="Select a term" expanded>
                <option v-for="term in terms" :key="term.id" :value="term.id">
                  {{ term.sessionTitle }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <nav class="level has-background-light py-2">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Subjects</p>
              <p class="title is-5">
                {{ ateResultScores.ateMarksAverageScore.number_of_subjects }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total</p>
              <p class="title is-5">
                {{ ateResultScores.ateMarksAverageScore.total }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Average</p>
              <p class="title is-5">
                {{ ateResultScores.ateMarksAverageScore.average }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Attendance</p>
              <p class="title is-5">
                {{
                  ateResultScores.termAttendance.total
                    ? ateResultScores.termAttendance.total
                    : 0
                }}
                /
                {{ ateResultScores.termAttendance.term.openDays }}
              </p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Comments</p>
              <fa-icon
                icon="check-circle"
                size="lg"
                :class="
                  ateResultScores.resultComments
                    ? 'has-text-success'
                    : 'has-text-danger'
                "
              />
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Psychomotors</p>
              <fa-icon
                icon="check-circle"
                size="lg"
                :class="
                  ateResultScores.resultPsychomotors
                    ? 'has-text-success'
                    : 'has-text-danger'
                "
              />
            </div>
          </div>
        </nav>
        <b-tabs v-model="activeTab">
          <b-tab-item label="ATE Scores">
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-table :data="ateResultScores.ateResultsRaw" hoverable>
                  <b-table-column
                    field="#"
                    label="#"
                    width="40"
                    numeric
                    v-slot="props"
                  >
                    {{ ateResultScores.ateResultsRaw.indexOf(props.row) + 1 }}
                  </b-table-column>

                  <b-table-column label="Subject" v-slot="props">
                    {{ props.row.subject_name }}
                  </b-table-column>

                  <b-table-column label="FA" v-slot="props">
                    {{ props.row.fa.score }}
                  </b-table-column>

                  <b-table-column label="SA" v-slot="props">
                    {{ props.row.sa.score }}
                  </b-table-column>

                  <b-table-column label="FT" v-slot="props">
                    {{ props.row.ft.score }}
                  </b-table-column>

                  <b-table-column label="ST" v-slot="props">
                    {{ props.row.st.score }}
                  </b-table-column>

                  <b-table-column
                    label="SKILLS"
                    v-slot="props"
                    v-if="schoolId === 4"
                  >
                    {{ props.row.aff.score + (props.row.psy ? props.row.psy.score : 0) }}
                  </b-table-column>

                  <b-table-column label="EX" v-slot="props">
                    {{ props.row.exams.score }}
                  </b-table-column>

                  <b-table-column label="Total" v-slot="props">
                    {{ props.row.total }}
                  </b-table-column>

                  <b-table-column label="Remark" v-slot="props">
                    {{ props.row.remark }}
                  </b-table-column>

                  <b-table-column
                    label="Actions"
                    width="160"
                    v-slot="props"
                    v-if="user.role !== 'parent'"
                  >
                    <p class="buttons">
                      <a
                        class="is-small has-text-success"
                        @click="initializeUpdate(props.row)"
                        style="font-size: 1em; margin-left: 16px"
                      >
                        <fa-icon icon="edit" size="lg" />
                      </a>
                    </p>
                  </b-table-column>

                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <fa-icon icon="info-circle" class="fa-5x" />
                        </p>
                        <p>No records to display</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
              <div class="column is-12">
                <p class="title is-6 is-bolder mb-0 mb-2">
                  <template v-if="user.role == 'parent'"> Attendance </template>
                  <template v-else>
                    <router-link
                      :to="`/school/${schoolId}/previous_attendance/${studentId}/term_id/${termId}`"
                      >Attendance</router-link
                    >
                  </template>
                </p>
                <p class="has-background-light p-3">
                  {{
                    ateResultScores.termAttendance.total
                      ? ateResultScores.termAttendance.total
                      : 0
                  }}
                </p>
              </div>
              <div class="column is-6">
                <p class="title is-6 is-bolder mb-0 mb-2">
                  <template v-if="user.role == 'parent'">
                    Educator's Comment
                  </template>
                  <template v-else>
                    <router-link
                      :to="`/school/${schoolId}/previous_result_comments/${studentId}/term_id/${termId}`"
                    >
                      Educator's Comment</router-link
                    >
                  </template>
                </p>
                <p class="has-background-light p-3">
                  {{
                    ateResultScores.resultComments &&
                    ateResultScores.resultComments.educatorComment
                      ? ateResultScores.resultComments.educatorComment
                          .description
                      : 'No Comment'
                  }}
                </p>
              </div>
              <div class="column is-6">
                <p class="title is-6 is-bolder mb-0 mb-2">
                  <template v-if="user.role == 'parent'">
                    Admin's Comment
                  </template>
                  <template v-else>
                    <router-link
                      :to="`/school/${schoolId}/previous_result_comments/${studentId}/term_id/${termId}`"
                    >
                      Admin's Comment</router-link
                    >
                  </template>
                </p>
                <p class="has-background-light p-3">
                  {{
                    ateResultScores.resultComments &&
                    ateResultScores.resultComments.adminComment
                      ? ateResultScores.resultComments.adminComment.description
                      : 'No Comment'
                  }}
                </p>
              </div>
              <div class="column is-12">
                <p class="title is-6 is-bolder mb-0 mb-2">
                  <template v-if="user.role == 'parent'">
                    Psychomotors
                  </template>
                  <template v-else>
                    <router-link
                      :to="`/school/${schoolId}/previous_result_psychomotors/${studentId}/term_id/${termId}`"
                      >Psychomotors</router-link
                    >
                  </template>
                </p>
                <template v-if="ateResultScores.resultPsychomotors">
                  <b-table
                    :data="ateResultScores.resultPsychomotors.psychomotors"
                    hoverable
                  >
                    <b-table-column
                      field="#"
                      label="#"
                      width="5%"
                      numeric
                      v-slot="props"
                    >
                      {{
                        ateResultScores.resultPsychomotors.psychomotors.indexOf(
                          props.row
                        ) + 1
                      }}
                    </b-table-column>

                    <b-table-column label="Name" width="55%" v-slot="props">{{
                      props.row.name
                    }}</b-table-column>

                    <b-table-column label="Value" width="20%" v-slot="props"
                      >{{ props.row.value }}
                    </b-table-column>

                    <b-table-column label="Remark" width="20%" v-slot="props">{{
                      props.row.remark
                    }}</b-table-column>
                  </b-table>
                </template>
              </div>
              <div
                class="column is-12 is-flex is-justify-content-flex-end mt-5"
                v-if="user.role !== 'parent'"
              >
                <button
                  type="submit"
                  class="button btn-120 is-primary is-capitalized"
                  @click="emailResult()"
                  :disabled="disableEmail"
                >
                  Email
                </button>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Result - PDF ">
            <div class="columns">
              <div class="column">
                <template v-if="result_path">
                  <iframe :src="result_path" />
                </template>
                <template v-else>
                  <loading>Fetching PDF...</loading>
                </template>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </template>
    </template>
    <!-- Edit Result -->
    <app-modal
      :context="context"
      @update="update"
      id="edit-result"
      :show-modal.sync="isModalOpen">
      <template v-slot:add>
        <p class="title is-5 has-text-right">{{ ate_score.subject_name }}</p>
        <SdInput
          label="FA"
          type="text"
          :rules="`required|min_value:0|max_value:${ate_score.fa.max_score}`"
          name="FA"
          v-model="ate_score.fa.score"
          :readonly="!ate_score.fa.marked"
          @set-change="setChange"
        />

        <SdInput
          label="SA"
          type="text"
          :rules="`min_value:0|max_value:${ate_score.sa.max_score}`"
          name="SA"
          v-model="ate_score.sa.score"
          :readonly="!ate_score.sa.marked"
          @set-change="setChange"
        />

        <SdInput
          label="FT"
          type="text"
          :rules="`min_value:0|max_value:${ate_score.ft.max_score}`"
          name="FT"
          v-model="ate_score.ft.score"
          :readonly="!ate_score.ft.marked"
          @set-change="setChange"
        />

        <SdInput
          label="ST"
          type="text"
          :rules="`min_value:0|max_value:${ate_score.st.max_score}`"
          name="ST"
          v-model="ate_score.st.score"
          :readonly="!ate_score.st.marked"
          @set-change="setChange"
        />

        <template v-if="schoolId === 4">
          <SdInput
            label="AFF"
            type="text"
            :rules="`min_value:0|max_value:${ate_score.aff.max_score}`"
            name="AFF"
            v-model="ate_score.aff.score"
            :readonly="!ate_score.aff.marked"
            @set-change="setChange"
          />
        </template>

        <SdInput
          label="EXAMS"
          type="text"
          :rules="`min_value:0|max_value:${ate_score.exams.max_score}`"
          name="EXAMS"
          v-model="ate_score.exams.score"
          :readonly="!ate_score.exams.marked"
          @set-change="setChange"
        />

        <label class="label">TOTAL</label>
        <div class="control" style="margin-bottom: 50px">
          <input
            class="input"
            type="text"
            :value="totalAteScore(ate_score)"
            readonly
          />
        </div>
      </template>
    </app-modal>
  </div>
</template>

<script>
import axios from 'axios'
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { crudNotification } from '../../assets/js/notification'
import { fetchTerm, fetchUser, fetchAppUrl } from '@/assets/js/app_info.js'

export default {
  name: 'result-scores-info',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Result Info',
      skipQuery: true,
      loading: false,
      result_path: null,
      activeTab: 0,
      context: 'update',
      ateResultScores: null,
      ate_score: {
        fa: { score: 0, marked: false },
        sa: { score: 0, marked: false },
        ft: { score: 0, marked: false },
        st: { score: 0, marked: false },
        aff: { score: 0, marked: false },
        exams: { score: 0, marked: false },
      },
      user: { id: null },
      terms: [],
      termTitle: null,
      termId: null,
      student: null,
      studentId: null,
      schoolClassId: null,
      updated: {},
      total: 0,
      average: 0,
      path: null,
      disableEmail: true,
    }
  },
  watch: {
    termId(termId) {
      this.fetchAteResult(termId)

      if (this.activeTab == 1) {
        this.result_path = null
        fetchAppUrl().then((path) => {
          this.loadPDF(path)
        })
      }
    },
    ateResultScores(data) {
      if (data.parent) {
        this.disableEmail =
          data.parent.emailOfFather === '' && data.parent.emailOfMother === ''
      }
      this.terms = data.resultTerms
    },
    activeTab(tab) {
      if (tab == 1) {
        fetchAppUrl().then((path) => {
          this.loadPDF(path)
        })
      }
    },
  },
  methods: {
    fetchAteResult(value) {
      this.$apollo.addSmartQuery('ateResultScores', {
        query: gql`
          query ateResultScoresQuery(
            $studentId: ID!
            $termId: Int!
            $userId: Int!
          ) {
            ateResultScores(
              studentId: $studentId
              termId: $termId
              userId: $userId
            ) {
              id
              name
              resultClass {
                id
                name
              }
              ateResultsRaw
              ateMarksAverageScore
              resultTerms {
                id
                sessionTitle
              }
              resultComments {
                adminComment {
                  id
                  description
                }
                educatorComment {
                  id
                  description
                }
              }
              resultPsychomotors {
                psychomotors
              }
              termAttendance {
                term {
                  openDays
                }
                total
              }
              parent {
                emailOfFather
                emailOfMother
              }
            }
          }
        `,
        variables: {
          studentId: this.studentId,
          termId: parseInt(value),
          userId: this.user.id,
        },
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
        fetchPolicy: 'no-cache',
      })
    },
    initializeUpdate(ate_score) {
      if (this.schoolId == 4) {
        ate_score.aff.score = ate_score.aff.score + (ate_score.psy ? ate_score.psy.score : 0)
      }

      this.ate_score = ate_score
      this.openModal()
    },
    update(validated) {
      if (validated) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateStudentAteMarks($data: JSON!) {
                updateAteMarks(input: { data: $data }) {
                  ateMark {
                    id
                    student {
                      id
                      name
                      ateMarksAverageScore
                    }
                  }
                  errors
                }
              }
            `,
            variables: { data: Object.values(this.updated) },
          })
          .then((response) => {
            this.total =
              response.data.updateAteMarks.ateMark[0].student.ateMarksAverageScore.total
            this.average =
              response.data.updateAteMarks.ateMark[0].student.ateMarksAverageScore.average
            crudNotification(
              response.data.updateAteMarks.errors,
              'Successfully updated.'
            ).then(() => {
              this.closeModal()
              this.$apollo.queries.ateResultScores.refetch()
            })
          })
          .catch((error) => {})
      }
    },
    setChange(changed, ate_name) {
      if (changed) {
        const identifier = ate_name.toLowerCase()
        this.updated[identifier] = {
          id: this.ate_score[identifier].id,
          score: this.ate_score[identifier].score,
          marked: this.ate_score[identifier].marked,
          created_at: this.ate_score[identifier].created_at,
          updated_at: new Date().toUTCString(),
        }
      }
    },
    totalAteScore(ate_score) {
      // TODO: REFACTOR
      const fa =
        ate_score.fa.score === '' || /\s+/.test(ate_score.fa.score.toString())
          ? 0
          : ate_score.fa.score
      const sa =
        ate_score.sa.score === '' || /\s+/.test(ate_score.sa.score.toString())
          ? 0
          : ate_score.sa.score
      const ft =
        ate_score.ft.score === '' || /\s+/.test(ate_score.ft.score.toString())
          ? 0
          : ate_score.ft.score
      const st =
        ate_score.st.score === '' || /\s+/.test(ate_score.st.score.toString())
          ? 0
          : ate_score.st.score
      const aff =
        ate_score.aff.score === '' || /\s+/.test(ate_score.aff.score.toString())
          ? 0
          : ate_score.aff.score
      const ex =
        ate_score.exams.score === '' ||
        /\s+/.test(ate_score.exams.score.toString())
          ? 0
          : ate_score.exams.score
      return (
        parseFloat(fa) +
        parseFloat(sa) +
        parseFloat(ft) +
        parseFloat(st) +
        parseFloat(aff) +
        parseFloat(ex)
      ).toFixed(2)
    },
    loadPDF(path) {
      axios
        .get(`${path}/publisher`, {
          params: {
            student_id: this.studentId,
            term_id: this.termId,
          },
        })
        .then((response) => {
          this.result_path = response.data.file
        })
        .catch((error) => {
          // console.log(error.message);
        })
    },
    emailResult() {
      this.disableEmail = true
      axios
        .get(`${this.path}/email_result`, {
          params: {
            student_id: this.studentId,
            term_id: this.termId,
          },
        })
        .then((response) => {
          if (response.data.message === 'Done') {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Successfully sent',
              type: 'is-success',
            })
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              message: 'Failed',
              type: 'is-danger',
            })
          }
          this.disableEmail = false
        })
        .catch((error) => {
          // console.log(error.message);
        })
    },
  },
  components: {
    SdInput,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.studentId = parseInt(this.$route.params.student_id)
    this.schoolClassId = parseInt(this.$route.params.school_class_id)

    if (this.schoolId == 4) {
      this.ate_score = {
        fa: { score: 0, marked: false },
        sa: { score: 0, marked: false },
        ft: { score: 0, marked: false },
        st: { score: 0, marked: false },
        aff: { score: 0, marked: false, max_score: 10 },
        exams: { score: 0, marked: false },
      }
    }

    fetchUser().then((user) => {
      this.user = user
      let menus = [
        {
          name: 'Result Class',
          route: `/school/${this.schoolId}/result_class/${this.schoolClassId}`,
        },
      ]
      if (user.role == 'host' || user.role == 'admin') {
        menus.push({
          name: 'Student Info',
          route: `/school/${this.schoolId}/school_class/${this.schoolClassId}/student_info/${this.studentId}`,
        })
      } else if (user.role == 'student') {
        menus = []
      } else if (user.role == 'parent') {
        menus = [
          {
            name: 'Students',
            route: `/school/${this.schoolId}/parents_students/${user.context}`,
          },
        ]
      }

      this.$store.commit('setSubMenus', menus)

      this.termId = parseInt(this.$route.params.term_id)
    })

    fetchAppUrl().then((path) => {
      this.path = path
    })
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
